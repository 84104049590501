<template>
  <div class="login_box relative">
    <img src="../assets/img/login/ic_logo.png" style="width: 122px" class="self-center"/>

    <h1 class="text-2xl font-bold text-white self-center "> 韩红基金会 </h1>
    <h1 class="text-sm text-white self-center mb-10"> HAN HONG LOVE FOUNDATION </h1>
    <div class="w-full max-w-md p-8 bg-white rounded-lg shadow-lg" id="app" data-v-app="">

      <div class="flex justify-center mb-6">
        <button class="ext-gray-400 border-b-2 border-transparent focus:outline-none " @click="$router.push('/login')">
          登录
        </button>
        <button class="text-primary border-b-2 border-red-400 focus:outline-none  ml-8">注册</button>
      </div>
      <div class="space-y-4">
        <div class="flex items-center border-b border-gray-300 py-2">
          <i class="iconfont icon-iconfontshouji text-gray-400 mr-2"/>
          <input class="w-full focus:outline-none text-gray-400"
                 v-model="mobile"
                 maxlength="11"
                 placeholder="请输入手机号" type="text">
        </div>
        <div class="flex items-center border-b border-gray-300 py-2">
          <Icon
              name="contact"
              class="icon text-gray-400 mr-2"
          ></Icon>
          <input class="w-full focus:outline-none text-gray-400"
                 v-model="invitaCode"
                 placeholder="请输入推荐码" type="text">
        </div>
        <div class="flex items-center border-b border-gray-300 py-2">
          <Icon
              name="lock"
              class="icon text-gray-400 mr-2"
          ></Icon>
          <input class="w-full focus:outline-none text-gray-400"
                 v-model="password"
                 placeholder="请输入密码" type="password">
        </div>
        <div class="flex items-center border-b border-gray-300 py-2">
          <Icon
              name="lock"
              class="icon text-gray-400 mr-2"
          ></Icon>
          <input class="w-full focus:outline-none text-gray-400"
                 v-model="password1"
                 placeholder="重新请输入密码" type="password">
        </div>
<!--        <div class="flex items-center border-b border-gray-300 py-2">-->
<!--          <Icon-->
<!--              name="lock"-->
<!--              class="icon text-gray-400 mr-2"-->
<!--          ></Icon>-->
<!--          <input class="w-full focus:outline-none text-gray-400"-->
<!--                 v-model="captcha"-->
<!--                 placeholder="验证码" >-->
<!--          <img :src="imgCodeUrl" style="height: 40px;margin-left: 10px" class="self-center" @click="changeKey"/>-->
<!--        </div>-->
      </div>
      <button class="w-full bg-primary text-white py-2 rounded mt-10" @click="onRegister">立即注册</button>
    </div>
  </div>
</template>

<script>
import axios from "../net/axios";
import api from "../net/api";
import {isNullOrEmpty} from "../utils";
import {Icon} from "vant";
import UserStorage from "@/utils/user_storage";
import {CUSTOME_SERVICE_CHAT_URL, GROUP_CHAT_URL} from "@/config";
import QRCode from "qrcodejs2";

export default {
  components: {Icon},
  data() {
    return {
      mobile: "",
      password: "",
      password1: "",
      invitaCode: "",
      key:new Date().getTime(),
      imgCodeUrl: "",
      captcha: "",
    };
  },
  mounted() {
    console.log("邀请码", this.$route.query.code);
    this.invitaCode = this.$route.query.code || "";
    this.imgCodeUrl= `${this.$config.API_URL}${api.siteCode}?key=${this.key}`
    // this.getImageCode();
    // this.generateGFQRCode();
    // this.generateKFQRCode();
  },
  methods: {
    jumpToPage(name) {
      // console.log('jumpTo',name)
      this.$router.push({name});
    },
    changeKey(){
      this.key=new Date().getTime()
      this.imgCodeUrl= `${this.$config.API_URL}${api.siteCode}?key=${this.key}`
    },
    onRegister() {
      if (isNullOrEmpty(this.mobile)) {
        this.$toast("请输入手机号");
        return;
      }
      if (isNullOrEmpty(this.password)) {
        this.$toast("请输入密码");
        return;
      }
      if (isNullOrEmpty(this.password1)) {
        this.$toast("请再次输入密码");
        return;
      }
      if (this.password !== this.password1) {
        this.$toast("两次密码输入不一致");
        return;
      }
      if (isNullOrEmpty(this.invitaCode)) {
        this.$toast("请输入推荐码");
        return;
      }
      // if (isNullOrEmpty(this.captcha)) {
      //   this.$toast("请输入验证码");
      //   return;
      // }
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      let params = {
        mobile: this.mobile,
        password: this.password,
        password1: this.password1,
        invita_code: this.invitaCode,
        // captcha: this.captcha,
        // key: this.key,
      };
      axios
          .post(api.register, params)
          .then((res) => {
            this.$toast.clear();
            console.log("注册成功", res);
            UserStorage.setToken(res.data.token);
            window.token = res.data.token;
            this.$router.replace({name: "home"});
          })
          .catch((e) => {
            this.$toast.clear();
            e.msg && this.$toast(e.msg);
          });
    },
    generateGFQRCode() {
      // 清空容器元素中的内容
      const qrcodeContainer = document.getElementById("gf_qrcode");
      if (qrcodeContainer) {
        qrcodeContainer.innerHTML = '';
        this.url = GROUP_CHAT_URL;
        new QRCode(qrcodeContainer, {
          text: this.url,
          width: 100,
          height: 100
        });
      }
    },
    generateKFQRCode() {
      // 清空容器元素中的内容
      const qrcodeContainer = document.getElementById("kf_qrcode");
      if (qrcodeContainer) {
        qrcodeContainer.innerHTML = '';
        new QRCode(qrcodeContainer, {
          text: CUSTOME_SERVICE_CHAT_URL,
          width: 100,
          height: 100
        });
      }
    },
  },
};
</script>
<style scoped lang="less">
.login_box {
  background-image: url('../assets/img/login/bg_login.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  padding: 80px 25px 25px 25px;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;

  .name {
    font-size: 24px;
    font-weight: 550;
    color: #fff;
  }
}
</style>
