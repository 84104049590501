<template>
  <div class="h-full w-full">
    <nav-bar
        :title="title"
        left-text=""
        :left-arrow="false"
        :border="false"
        :safe-area-inset-top="true"
    />
    <div class="overflow-hidden w-full">
      <div class="page-content">
        <Banner></Banner>
        <HomeMenu></HomeMenu>
        <div class="px-4">
          <div class="text-T010101 text-center font-bold text-xl mb-2">视频新闻</div>
          <HomeVideo/>
        </div>
        <div class="text-T010101 text-center font-bold text-xl mb-2 mt-4">扶贫新闻</div>
        <News></News>
      </div>
    </div>

    <popup class="bg-transparent" style="max-width: 90%;overflow-x: hidden;" v-model="dialog" position="center"
           :closeable="false">
      <div class="bg-white rounded-3xl">
        <div class="notice-title flex flex-row justify-center text-T010101 text-2xl items-center">
          <img src="@/assets/img/index/ic_notice.png" alt="" style="width: 25px;height: 25px;margin-right: 10px;">
          公告消息
        </div>
        <div class="bg-white p-2 m-0 w-full overflow-y-auto" style="max-height: 400px;">
          <p v-html="problem" class="text-[16px]"></p>

        </div>
        <div class=" py-4 text-[16px] flex justify-center" @click="closeDialog(1)">
          <div class="bg-primary text-white px-10 py-2 rounded-full">
            我知道了
          </div>
        </div>
      </div>
    </popup>
    <popup class="bg-transparent" style="max-width: 90%;overflow-x: hidden;" v-model="dialog2" position="center"
           :closeable="false">
      <div class="bg-white rounded-3xl">
        <div class="notice-title flex flex-row justify-center text-T010101 text-2xl items-center">
          <img src="@/assets/img/index/ic_notice.png" alt="" style="width: 25px;height: 25px;margin-right: 10px;">
          公告消息
        </div>
        <div class="bg-white p-2 m-0 w-full overflow-y-auto" style="max-height: 400px;">
          <p v-html="introduce" class="text-[16px]"></p>
        </div>
        <div class=" py-4 text-[16px] flex justify-center" @click="closeDialog(2)">
          <div class="bg-primary text-white px-10 py-2 rounded-full">
            我知道了
          </div>
        </div>
      </div>
    </popup>
    <popup class="bg-transparent" style="max-width: 90%;overflow-x: hidden;" v-model="dialog3" position="center"
           :closeable="false">
      <div class="bg-white rounded-3xl">
        <div class="notice-title flex flex-row justify-center text-T010101 text-2xl items-center">
          <img src="@/assets/img/index/ic_notice.png" alt="" style="width: 25px;height: 25px;margin-right: 10px;">
          公告消息
        </div>
        <div class="bg-white p-2 m-0 w-full overflow-y-auto" style="max-height: 400px;">
          <p v-html="home_show" class="text-[16px]"></p>
        </div>
        <div class=" py-4 text-[16px] flex justify-center" @click="closeDialog(3)">
          <div class="bg-primary text-white px-10 py-2 rounded-full">
            我知道了
          </div>
        </div>
      </div>
    </popup>
  </div>
</template>
<script>
import Banner from "@/components/home/Banner";
import HomeMenu from "@/components/home/HomeMenu";
import News from "@/components/home/News";
import {mapActions, mapState} from "vuex";
import {isNullOrEmpty} from "@/utils";
import {NavBar, Popup,} from "vant";
import HomeVideo from "@/components/home/HomeVideo";

export default {
  name: "index",
  components: {HomeVideo, NavBar, Banner, HomeMenu, News, Popup},
  computed: {
    ...mapState("user", ["userInfo"]),
    ...mapState("systemInfo", ["systemInfo"]),
    title() {
      console.log('title', this.$route.meta.title)
      return this.$route.meta.title; // 获取路由的meta属性的key值
    }
  },
  data() {
    return {
      dialog: false,
      dialog2: false,
      dialog3: false,
      problem: null,
      introduce: null,
      home_show: null,
    };
  },
  created() {
    // 从本地存储中获取 this.problem 的值
    const savedProblem = sessionStorage.getItem('problem');
    if (savedProblem) {
      this.problem = savedProblem;
    }
  },
  beforeDestroy() {
    // 在组件销毁之前将 this.problem 的值保存到本地存储
    sessionStorage.setItem('problem', this.problem);
  },
  watch: {
    systemInfo(newVal) {
      if (!isNullOrEmpty(newVal.problem)) {
        this.dialog = true;
        this.problem = newVal.problem;
      }
      if (!isNullOrEmpty(newVal.introduce)) {
        this.introduce = newVal.introduce;
      }
      // if (!isNullOrEmpty(newVal.home_show)) {
      //   this.home_show = newVal.home_show;
      // }
    }
  },
  mounted() {
    console.log("首页mounted");
    this.getUserInfo();
    this.getSystemInfo();
  },
  methods: {
    ...mapActions("user", ["getUserInfo"]),
    ...mapActions("systemInfo", ["getSystemInfo"]),
    closeDialog(type) {
      if (type === 1) {
        this.dialog = false;
        if (!isNullOrEmpty(this.introduce)) {
          this.dialog2 = true;
        }
      } else if (type === 2) {
        this.dialog2 = false;
        if (!isNullOrEmpty(this.home_show)) {
          this.dialog3 = true;
        }
      } else {
        this.dialog3 = false;
      }
    }
  },

};
</script>

<style scoped lang="less">
//.van-nav-bar {
//  background-color: #B41212;
//}

//.van-nav-bar /deep/ .van-nav-bar__title {
//  color: #fff;
//}

.bg-top {
  background-image: linear-gradient(180deg, #7496FF 0%, rgba(116, 150, 255, 0.00) 100%);
  height: 166px;
}

.bottom {
  background-image: linear-gradient(113deg, #4B49FF 0%, #7D9AFF 100%);
  border-radius: 4px;
}

.notice-title {
  width: 320px;
  height: 67px;
}

.notice-btn {
  background-image: url('../../assets/img/index/bg_notice_btn.png');
  background-size: 100%;
  background-repeat: no-repeat;
  width: 258px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
