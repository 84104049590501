<template>
  <div class="h-full w-full bg-page">
    <div class="overflow-hidden w-full">
      <div class="page-content-2 pb-safe">
        <div class="flex flex-col m-4">
          <div class="flex flex-row items-center bg-info">
            <div class="bg-white rounded-full p-1 mr-2">
              <img class="rounded-full w-12 h-12 " @cilck="jumpToPage('userInfo')"
                   :src="userInfo.avatar?userInfo.avatar: require('@/assets/img/d-avatar.png')"
                   alt="">
            </div>
            <div class=" flex-1 flex text-white flex-col">
              <div class="flex flex-row items-center justify-between">
                <p class="font-bold text-base">{{ displayName }}</p>
              </div>
              <div class="flex flex-row items-center justify-between">
                <div>
                  <p class="text-sm mt-1">手机号:{{ userInfo.mobile|maskPhoneNumber }}</p>
                </div>
                <img src="@/assets/img/profile/btn_sign.png" style="height: 26px;" @click="onSignIn"
                     alt=""/>
              </div>
            </div>
          </div>
          <div class="bg-E0C28E rounded-lg p-1 my-2" @click="jumpToPage('realNameAuth')">
            <div class="bg-white flex flex-row items-center text-base text-white rounded-lg p-2">
              <div class="flex flex-1 flex-row items-center text-724A07">
                <img src="@/assets/img/profile/ic_realname.png" alt="" style="height: 12px ;margin-right: 5px;">
                <div class="text-sm">实名注册赠送200原始股和50000扶贫金</div>
              </div>
              <div class="rounded-full bg-E7CA98 text-724A07 text-center  text-xs px-2 py-1 ">
                我要实名
              </div>
            </div>
          </div>
          <img src="@/assets/img/profile/rdsq.png" alt="" class="w-full" @click="showDialog">
          <div class="bg-E0C28E rounded-lg p-1 my-2">
            <MyAssets/>
          </div>
          <UserTools/>
          <button class="bg-E0C28E text-724A07 font-bold text-lg rounded-full text-center py-2 my-4  "
                  @click="onLoginOut">退出登录
          </button>
        </div>
      </div>
    </div>
    <popup class="bg-transparent" style="width: 90%; height: auto; max-height: 90vh; overflow: hidden;" v-model="dialog"  position="center"
            >
      <div class="relative w-full h-full flex flex-col justify-center items-center ">
        <img src="@/assets/img/profile/rd-bg.png" alt="Background" class="absolute inset-0 w-full h-full"/>
        <!-- Content -->
        <div class="relative z-10 p-4 text-center">
          <!-- Flag and title -->
          <div class="mb-16">
            <h2 class="text-2xl font-bold text-red-600 text-center">入党宣言</h2>
          </div>

          <!-- Main text -->
          <p class="text-sm mb-6 text-left text-603E06">
            我志愿加入中国共产党，拥护党的纲领，遵守党的章程，
            履行党员义务，执行党的决定，严守党的纪律，保守党的
            秘密，对党忠诚，积极工作，为共产主义奋斗终身，随时
            准备为党和人民牺牲一切，永不叛党
          </p>

          <!-- Input field -->
          <div class="mb-6 text-left flex flex-row items-center justify-end">
            <div class="block text-sm mb-1 text-603E06">申请人：</div>
            <input type="text" class= "px-2 py-1 bg-transparent" style="border-bottom: 1px solid #603E06;"  v-model="userName" />
          </div>

          <!-- Additional info -->
          <div class="text-xs text-603E06 mb-4 whitespace-nowrap font-bold">
            需支付800元入党申请费，入党成功后，每个月返3万补贴金。
          </div>

          <!-- Submit button -->
          <button class="bg-red-500 text-white px-8 py-2 rounded-full text-lg font-bold self-center" @click="applyRD">
            立即申请
          </button>
        </div>
      </div>
    </popup>
  </div>
</template>
<script>
import {mapActions, mapState} from "vuex";
import {WithdrawalType} from "@/config";
import axios from "@/net/axios";
import api from "@/net/api";
import {isNullOrEmpty} from "@/utils";
import MyAssets from "@/components/MyAssets";
import UserTools from "@/components/UserTools";
import {Popup} from "vant";

export default {
  name: "index",
  components: {UserTools, MyAssets, Popup},
  computed: {
    ...mapState("user", ["userInfo"]),
    ...mapState("systemInfo", ["systemInfo"]),
    title() {
      return this.$route.meta.title; // 获取路由的meta属性的key值
    },
    displayName: function () {
      if (this.userInfo.auth?.realname) {
        return this.userInfo.auth?.realname;
      } else if (this.userInfo.nickname) {
        return this.userInfo.nickname;
      } else {
        return "未设置";
      }
    },
  },
  data() {
    return {
      invitaCode: "",
      shareUrl: "",
      shouldGenerateQRCode: false,
      WithdrawalType,
      walletInfo: {},
      recommendRecords: [],
      dialog: false,
      userName: "",
    };
  },
  mounted() {
    this.getUserInfo();
    this.getSystemInfo();
    this.getUserWallet();
  },
  filters: {
    toIntegerOrZero(value) {
      if (value !== undefined) {
        return Math.floor(value);
      } else {
        return 0;
      }
    },
    maskPhoneNumber(phoneNumber) {
      const maskedNumber = phoneNumber?.replace(/(\d{3})\d{4}(\d{4})/, '\$1****\$2');
      return maskedNumber;
    },
  },
  methods: {
    ...mapActions("user", ["getUserInfo"]),
    ...mapActions("systemInfo", ["getSystemInfo"]),
    onSignIn(e) {
      if (isNullOrEmpty(this.userInfo.auth)) {
        this.$dialog.confirm({
          title: '提示',
          message: '签到需要实名认证，是否前往实名认证？',
        })
            .then(() => {
              this.$router.push({name: 'realNameAuth'});
            })
            .catch(() => {
            });
        return
      }
      // 2未签到 1已签到
      if (e == 1) {
        this.$toast("今日已签到完成！");
        return false;
      }
      axios
          .get(api.signIn)
          .then((res) => {
            this.$toast.success('签到成功!');
            this.getUserInfo();
          })
          .catch((err) => {
            err.msg && this.$toast(err.msg);
          });
    },
    jumpToWithdraw(type, title, amount) {
      if (type == 'annuity' && this.userInfo.activate == 0) {
        this.$dialog.alert({
          title: '提示',
          message: '购买养老基金即可提现',
          theme: 'round-button',
        }).then(() => {
          this.$router.push({
            name: "dividend"
          });
        });
        return

      }
      this.$router.push({
        name: "withdrawal",
        query: {type, title, amount},
      });
    },
    jumpToPage(name) {
      if (name == 'downloadApp') {
        window.location.href = this.$config.APP_URL.replace('./', '/');
        return
      }
      if (name == 'customService') {
        window.location.href = this.$config.KF_URL;
        return
      }
      this.$router.push({name});
    },
    onLoginOut() {
      this.$dialog.confirm({
        title: '提示',
        message: '确定要退出登录吗？',
      })
          .then(() => {
            this.$store.commit("tabs/ACCOUNT_LOGOUT_FAILURE", true);
          })
          .catch(() => {
          });
    },
    getUserWallet() {
      axios.get(api.userWallet)
          .then((res) => {
            this.walletInfo = res.data;
          })
          .catch((err) => {
            console.log(err);
          });
    },
    showStockValue() {
      this.$dialog.alert({
        title: '提示',
        message: `当前市值：${this.accountStatistic.stockPrice}元`,
      });
    },
    receiveNote() {
      if (isNullOrEmpty(this.userInfo.auth)) {
        this.$dialog.confirm({
          title: '提示',
          message: '领取本票需要实名认证，是否前往实名认证？',
        })
            .then(() => {
              this.$router.push({name: 'realNameAuth'});
            })
            .catch(() => {
            });
        return
      }
      axios
          .post(api.receiveNote)
          .then((res) => {
            console.log('res', res.data)
            this.$dialog.alert({
              title: '提示',
              message: `领取成功，请在“我的卡包”查看`,
            });
          })
          .catch((err) => {
            err.msg && this.$toast(err.msg);
          });
    },
    loadMore() {
      axios
          .get(api.receiveList)
          .then((res) => {
            if (res.data && res.data.length > 0) {
              this.recommendRecords = res.data.map(item => {
                return item.recom_num
              })
            }
            this.loading = false
            this.refreshing = false
            this.finished = true;
          })
          .catch((err) => {
            this.loading = false
            this.refreshing = false
            err.msg && this.$toast(err.msg);
          });
    },
    showDialog() {
      if(this.userInfo.is_party_member==0){
        this.dialog = true;
      }
      else{
        this.$dialog.alert({
          title: '提示',
          message: '您已是党员，无需申请入党',
          theme: 'round-button',
        });
      }
    },
    applyRD() {
      if (isNullOrEmpty(this.userName)) {
        this.$toast("请输入申请人姓名");
        return
      }
      this.$router.push({
        name: "buyAssets",
        query: {
          id: 1,
          name: '入党申请费',
          price: 800,
          source:6,
        },
      });
    },
  },
};
</script>

<style scoped lang="less">
//.van-nav-bar {
//  background-color: #B41212;
//}
//
//.van-nav-bar /deep/ .van-nav-bar__title {
//  color: #fff;
//}
.bg-page {
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #F74042 4.74%, #FA7B5F 73.06%, #FDB574 96.45%);
  backdrop-filter: blur(14.9905px);
}


.header {
  background-image: url('../../assets/img/profile/bg-avatar.png');
  width: 62px;
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: contain;
}

.header a img {
  width: 48px;
  height: 48px;
}


.item-box {
  background: linear-gradient(0deg, #515253 0%, #333435 100%);
  box-shadow: 0px 5px 10px 3px rgba(20, 7, 0, 0.17);
  border-radius: 10px;
}


.item-btn {
  width: 100%;
  background-color: #FFFFFF;
  border-radius: 8px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #333435;
  margin: 20px auto auto;
}


</style>
