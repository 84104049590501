<template>
  <div class="h-full w-full bg-invite">
    <nav-bar
        :title="title"
        left-text=""
        :left-arrow="false"
        :border="false"
        :safe-area-inset-top="true"
    />
    <div class="overflow-hidden w-full">
      <div class="p-4 page-content ">
        <PullRefresh v-model="refreshing" @refresh="refreshData" class="h-full">
          <list
              v-model="loading"
              :finished="true"
              @load="loadMore"
              @refresh="refreshData"
          >
            <div class="p-2">
              <div class="w-full  items-center mb-4">
                <img src="@/assets/img/invite/icon.png" class="w-full">
              </div>
              <div class="flex flex-col bg-white rounded-md mb-2" v-for="(item,index) in list" :key="index">
                <div class="rounded-t-md bg-E7CA98 text-8A5F4D p-2 text-center">邀请好友</div>
                <div class="flex-row flex items-center p-2 text-base">
                  <div>满<span class="text-red-500 font-bold">{{ item.num }}</span>名</div>
                  <div class="flex flex-1 flex-col items-center pl-2 pr-6">
                    <div class="text-base text-T010101">奖励原始股</div>
                    <div class="text-base text-red-500 font-bold">{{ `${item.stock}股` }}</div>
                  </div>
                  <div class="flex  flex-col items-center pl-1 pr-3">
                    <div class="text-base text-T010101">推广金</div>
                    <div class="text-base text-red-500 font-bold">{{ `${item.amount}元` }}</div>
                  </div>
                  <div
                      class="rounded-full  font-bold bg-E7CA98 py-1 px-2 text-sm flex flex-row justify-center items-center"
                      style="height: 30px;"
                      @click="jumpToPage(item,index)"
                      v-html="getFormatLabel(item.num)"
                      :class="{ 'bg-E7CA98':getEnable(item.num),'bg-C6D7278':!getEnable(item.num),'text-red-800':getEnable(item.num), 'text-white':!getEnable(item.num) }"
                  >
                  </div>
                </div>
              </div>
              <div class="text-white text-base">注：每邀请一位好友实名注册还可获得10000扶贫金</div>
            </div>
          </list>
        </PullRefresh>
      </div>
    </div>
  </div>
</template>

<script>


import {List, NavBar, PullRefresh,} from "vant";
import {mapActions, mapState} from "vuex";
import axios from "@/net/axios";
import api from "@/net/api";

export default {
  name: "index",
  components: {NavBar, List, PullRefresh,},
  data() {
    return {
      type: 0,
      dialog: false,
      name: '',
      phone: '',
      address: '',
      list: [
        {
          id: 1,
          num: 10,
          stock: 100,
          amount: 10,
        },
        {
          id: 2,
          num: 20,
          stock: 300,
          amount: 30,
        },
        {
          id: 3,
          num: 50,
          stock: 1000,
          amount: 100,
        },
        {
          id: 4,
          num: 100,
          stock: 3000,
          amount: 200,
        },
        {
          id: 5,
          num: 300,
          stock: 10000,
          amount: 800,
        },
        {
          id: 6,
          num: 1000,
          stock: 50000,
          amount: 3000,
        },
        {
          id: 7,
          num: 3000,
          stock: 200000,
          amount: 10000,
        },
      ],
      recommendRecords: [],
      loading: false,
      finished: false,
      refreshing: false,
    };
  },
  computed: {
    ...mapState("user", ["userInfo"]),
    title() {
      return this.$route.meta.title; // 获取路由的meta属性的key值
    }
  },
  mounted() {
    this.getUserInfo();
    this.loadMore()
  },
  methods: {
    ...mapActions("user", ["getUserInfo"]),
    getFormatLabel(inviteNum) {
      if (this.recommendRecords?.includes(inviteNum)) {
        return "&nbsp;&nbsp;已领取&nbsp;&nbsp;";
      }
      else if(this.userInfo.tjnum >= inviteNum){
        return "立即领取";
      }
      return "&nbsp;&nbsp;未达标&nbsp;&nbsp;";
    },
    getEnable(inviteNum) {
     if(this.userInfo.tjnum >= inviteNum){
        return true
      }
      return false;
    },
    jumpToPage(item, index) {
      if (this.userInfo.tjnum >= item.num) {
        if (this.recommendRecords.includes(item.num)) {
          this.$toast('该奖项已被领取');
          return;
        }
        this.receiveAward(item.num)
      } else {
        this.$router.push({name: 'inviteUser'})
      }
    },
    receiveAward(level) {
      axios
          .get(api.receiveAward, {params: {level}})
          .then((res) => {
            this.$toast("领取成功");
            this.loadMore();
            this.dialog = false;
          })
          .catch((err) => {
            this.dialog = false;
            err.msg && this.$toast(err.msg);
          });

    },
    refreshData() {
      this.refreshing = true
      this.loadMore()
    },
    loadMore() {
      axios
          .get(api.receiveList)
          .then((res) => {
            if (res.data && res.data.reclist.length > 0) {
              this.recommendRecords = res.data.reclist.map(item => {
                return item.recom_num
              })
            }
            this.loading = false
            this.refreshing = false
          })
          .catch((err) => {
            this.loading = false
            this.refreshing = true
            err.msg && this.$toast(err.msg);
          });
    },

  },
};
</script>

<style scoped lang="less">
//.van-nav-bar {
//  background-color: #B41212;
//}
//
//.van-nav-bar /deep/ .van-nav-bar__title {
//  color: #fff;
//}

.grade-icon {
  height: 46px;
}

.get-icon {
  height: 46px;
}

.title-icon {
  width: 220px;
}

.page-content /deep/ .van-loading__text {
  color: #fff;
}

.page-content /deep/ .van-pull-refresh__head {
  color: #fff;
}

.page-content /deep/ .van-empty__description {
  color: #fff;
}

.bg-invite {
  background-image: url('../../assets/img/invite/bg_invite.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
</style>
