// 该文件专门用于创建整个应用的路由器
import VueRouter from "vue-router";
import Login from "@/pages/login";
import Register from "../pages/register";
import Home from "../pages/home";
import UserStorage from "../utils/user_storage";
import NewsDetails from "@/pages/news/NewsDetails";
import Invite from "@/pages/invite";
import InviteUser from "@/pages/invite/user";
import Main from "@/pages/main";
import AssetsRecord from "@/pages/assets/record";
import BuyAssets from "@/pages/assets/buy";
import Dividend from "@/pages/dividend";
import BuyBonusList from "@/pages/dividend/record";
import BuyDividend from "@/pages/dividend/buy";
import Profile from "@/pages/profile";
import UserInfo from "@/pages/profile/details";
import RealNameAuth from "@/pages/profile/realNameAuth";
import BindBankCard from "@/pages/profile/bindBankCard";
import Withdraw from "@/pages/profile/withdraw";
import WithdrawRecord from "@/pages/profile/withdrawRecord";
import FundDetails from "@/pages/profile/fundDetails";
import ContactUs from "@/pages/settings/contactUs";
import Team from "@/pages/team";
import ChildTeam from "@/pages/team/child";
import CustomService from "@/pages/settings/customService";
import Award from "@/pages/award";
import AwardRecord from "@/pages/award/record";
import MyHouse from "@/pages/profile/myHouse";
import MyCards from "@/pages/profile/myCards";
import AddressList from "@/pages/address/addressList";
import EditAddress from "@/pages/address/editAddress";
import BankPay from "@/pages/dividend/bankPay";
import Gift from "@/pages/dividend/gift";
import PaySxf from "@/pages/dividend/paysxf";
import PayLarge from "@/pages/dividend/payLarge";

//创建并暴露一个路由器
const router = new VueRouter({
    mode: "history",
    routes: [
        {
            path: "/",
            component: Main,
            name: "main",
            meta: {title: "主页"},
            children: [
                {
                    path: "/home",
                    component: Home,
                    name: "home",
                    meta: {title: "首页"},
                },
                {
                    path: '/invite',
                    component: Invite,
                    name: 'invite',
                    meta: {title: '推广大使'},
                },
                {
                    path: "/award",
                    component: Award,
                    name: "award",
                    meta: {title: "抽奖"},
                },
                {
                    path: "/dividend",
                    component: Dividend,
                    name: "dividend",
                    meta: {title: "扶贫攻坚"},
                },{
                    path: "/gift",
                    component: Gift,
                    name: "gift",
                    meta: {title: "建军礼包"},
                },
                {
                    path: "/profile",
                    component: Profile,
                    name: "profile",
                    meta: {title: "我的"},
                },
                {
                    path: '/',
                    redirect: '/home',
                },
            ]
        },
        {
            path: "/login",
            component: Login,
            name: "login",
            meta: {title: "登录"},
        },
        {
            path: "/register",
            component: Register,
            name: "register",
            meta: {title: "注册"},
        },

        {
            path: '/news/details/:id',
            component: NewsDetails,
            name: 'newsDetails',
            meta: {title: '新闻资讯'},
        },
        {
            path: '/contactus',
            component: ContactUs,
            name: 'contactUs',
            meta: {title: '官方群聊'},
        }, {
            path: '/customService',
            component: CustomService,
            name: 'customService',
            meta: {title: '在线客服'},
        },
        {
            path: '/invite/user',
            component: InviteUser,
            name: 'inviteUser',
            meta: {title: '邀请好友'},
        },
        {
            path: '/team',
            component: Team,
            name: 'team',
            meta: {title: '我的团队'},
        },
        {
            path: '/team/child/:level',
            component: ChildTeam,
            name: 'childTeam',
            meta: {title: '我的团队'},
        },
        {
            path: "/assets/buyAssets",
            component: BuyAssets,
            name: "buyAssets",
            meta: {title: "立即恢复"},
        },
        {
            path: "/dividend/buy",
            component: BuyDividend,
            name: "buyDividend",
            meta: {title: "立即购买"},
        },
        {
            path: "/dividend/record",
            component: BuyBonusList,
            name: "buyBonusList",
            meta: {title: "我的投资"},
        },
        {
            path: "/profile/details",
            component: UserInfo,
            name: "userInfo",
            meta: {title: "个人资料"},
        }, {
            path: "/profile/realNameAuth",
            component: RealNameAuth,
            name: "realNameAuth",
            meta: {title: "实名认证"},
        }, {
            path: "/profile/bindBankCard",
            component: BindBankCard,
            name: "bindBankCard",
            meta: {title: "绑定银行卡"},
        },
        {
            path: "/profile/withdraw",
            component: Withdraw,
            name: "withdrawal",
            meta: {title: "提现"},
        },
        {
            path: "/assets/record",
            component: AssetsRecord,
            name: "assetsRecord",
            meta: {title: "资产恢复记录"},
        },{
            path: "/profile/withdrawRecord",
            component: WithdrawRecord,
            name: "withdrawRecord",
            meta: {title: "提现记录"},
        },
        {
            path: "/profile/fundDetails",
            component: FundDetails,
            name: "fundDetails",
            meta: {title: "资金明细"},
        },{
            path: "/award/record",
            component: AwardRecord,
            name: "awardRecord",
            meta: {title: "抽奖记录"},
        },
        {
            path: "/myHouse",
            component: MyHouse,
            name: "myHouse",
            meta: {title: "我的房产"},
        },{
            path: "/myCards",
            component: MyCards,
            name: "myCards",
            meta: {title: "我的卡包"},
        },{
            path: "/address",
            component: AddressList,
            name: "address",
            meta: {title: "收货地址"},
        },{
            path: "/editAddress/:id",
            component: EditAddress,
            name: "editAddress",
            meta: {title: "编辑收货地址"},
        },{
            path: "/dividend/bankpay",
            component: BankPay,
            name: "bankPay",
            meta: {title: "银行卡支付"},
        },{
            path: "/dividend/paysxf",
            component: PaySxf,
            name: "paysxf",
            meta: {title: "立即支付"},
        },,{
            path: "/dividend/payLarge",
            component: PayLarge,
            name: "payLarge",
            meta: {title: "立即支付"},
        },
    ],
});

/**
 * 解决报错问题：报错显示是路由重复
 * Error: Avoided redundant navigation to current location
 */
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    // 判断目标路由是否与当前路由相同
    if (location.path !== this.currentRoute.path) {
        return originalPush.call(this, location).catch(err => err);
    }
}
//全局后置路由守卫————初始化的时候被调用、每次路由切换之后被调用
router.afterEach((to, from) => {
    //切换路由，页面直接滚动到顶部
    // document.body.scrollTop = 0;
    // document.documentElement.scrollTop = 0;
});

// 导航守卫
router.beforeEach((to, from, next) => {
    if (to.path === "/login" || to.path === "/register"|| to.path === "/contactus"|| to.path === "/customService") {
        // 如果目标页面是登录页，直接放行
        return next()
    }
    const isAuthenticated = UserStorage.getToken(); // 判断用户是否已登录的函数
    // 如果目标页面不是登录页
    if (!isAuthenticated && to.path !== '/login') {
        return next('/login')
    }
    next();
});

export default router;
