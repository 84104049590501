<template>
  <div class="page-container h-screen">
    <nav-bar
        :title="title"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        :safe-area-inset-top="true"
        class="w-full"
        :border="false"
    />
    <div class="overflow-hidden w-full">
      <div class="overflow-y-auto h-full">
        <div class="text-sm text-b666 mx-2 my-4">请认真核实上传信息，以免对你的财产造成损失</div>
        <form @submit="onSubmit" class="rounded-sm bg-white p-2 m-2">
          <field
              v-model="realname"
              name="realname"
              label="真实姓名"
              placeholder="请输入真实姓名"
              :center="true"
              input-align="right"
              :readonly="isAuth"
          />
          <field
              v-model="idcard"
              name="idcard"
              label="身份证号"
              placeholder="请输入身份证号码"
              :center="true"
              input-align="right"
              :readonly="isAuth"
          />
          <div class="mt-8 mb-2 mx-2" v-if="!isAuth">
            <Button round block type="info" color="#FF5848" native-type="submit">立即实名</Button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>

import TitleBar from "@/components/TitleBar";
import {mapActions, mapState} from "vuex";
import {NavBar, CellGroup, Button, Icon, Form, Field} from "vant";
import axios from "@/net/axios";
import api from "@/net/api";
import {WithdrawalType} from "@/config";
import {isNullOrEmpty} from "@/utils";

export default {
  components: {TitleBar, NavBar, CellGroup, Button, Icon, Form, Field},
  computed: {
    ...mapState("user", ["userInfo"]),
  },
  watch: {
    userInfo(newVal, oldVal) {
      if (newVal.auth) {
         this.isAuth = true;
         this.realname = newVal.auth.realname;
         this.idcard = newVal.auth.idcard;
      }
    },
  },
  mounted() {
    this.getUserInfo();
  },
  data() {
    return {
      title: this.$route.meta.title,
      WithdrawalType,
      idcard: "",
      realname: "",
      isAuth: false,
    }
  },
  methods: {
    ...mapActions("user", ["getUserInfo"]),
    onClickLeft() {
      this.$router.go(-1);
    },
    onSubmit(event) {
      event.preventDefault();
      const {idcard, realname} = this;
      if (isNullOrEmpty(realname)) {
        this.$toast("请输入真实姓名");
        return
      }
      const regName = /^[\u4e00-\u9fa5]+·[\u4e00-\u9fa5]+$/;
      const regName1 = /^([\u4E00-\u9FA5])*$/;
      if (!regName.test(this.realname) && !regName1.test(this.realname)) {
        this.$toast('真实姓名填写有误!')
        return;
      }

      if (isNullOrEmpty(idcard)) {
        this.$toast("请输入身份证号码");
        return
      }

      const regExp = /(^\d{15}$)|(^\d{17}(\d|X|x)$)/;
      if (!regExp.test(idcard)) {
        this.$toast('身份证号填写有误!')
        return;
      }
      this.$toast.loading({
        message: "提交中...",
        forbidClick: true,
      });
      axios.post(api.realAuth, {
        idcard,
        realname,
      }).then((res) => {
        this.$toast.clear();
        this.$toast("实名认证成功");
        this.getUserInfo();
        this.onClickLeft();
      }).catch((err) => {
        this.$toast.clear();
        err.msg && this.$toast(err.msg);
      });
    },
  },

}
</script>

<style scoped lang="less">

</style>
