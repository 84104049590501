<template>
  <div class="page-container h-screen w-screen">
    <nav-bar
        left-text=""
        left-arrow
        :title="title"
        @click-left="onClickLeft"
        :safe-area-inset-top="true"
        class="w-full"
    />
    <div class="flex-grow overflow-hidden w-full">
      <div class="overflow-y-auto h-full">
        <div class="welfare-list">
          <div class="item">
            <p class="title" style="color: #fff;">{{query.title}}</p>
            <ul class="ul">
              <li>
                <p class="pre">{{query.bonus|formatDecimal2}}<span>元</span></p>
                <p style="color: #fff;">{{query.subtitle}}</p>
              </li>
              <li v-if="query.subtitle2">
                <p class="pre">{{query.stock |formatDecimal2}}</p>
                <p style="color: #fff;">{{query.subtitle2}}</p>
              </li>
              <li>
                <p class="pre">{{query.price|formatDecimal2}}<span>元</span></p>
                <p style="color: #fff;">{{query.subtitle3}}</p>
              </li>
            </ul>
          </div>
        </div>
        <radio-group v-model="selectedValue">
          <cell-group title="支付方式" class="m-3 rounded-b">
            <cell v-for="(item, index) in paymentList"
                  :key="index"
                  clickable
                  :title="item.name"
                  @click="selectPayment(item)"
                  v-if="systemInfo[item.is_show]"
                  class="flex flex-row items-center"
            >
              <template #icon>
                <img :src="item.icon" class="h-5 mr-1" />
              </template>
              <template #right-icon>
                <radio :name="getCompositeKey(item.type, item.channel)" />
              </template>
            </cell>
          </cell-group>
        </radio-group>
        <div class="annius_S">
          <button type="button" @click="onPay">立即支付</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/**
 * 购买分红股
 */
import {mapActions, mapState} from "vuex";
import {Cell, CellGroup, NavBar, Radio, RadioGroup} from "vant";
import axios from "@/net/axios";
import api from "@/net/api";
import {isNullOrEmpty} from "@/utils";

export default {
  components: { NavBar, CellGroup, Cell, RadioGroup, Radio},
  computed: {
    ...mapState("systemInfo", ["systemInfo"]),
    title() {
      return this.$route.meta.title; // 获取路由的meta属性的key值
    }
  },
  watch: {
    selectedValue(newValue) {
      const [type, channel] = newValue.split('-');
      this.payType = type;
      this.channel = channel;
    }
  },
  filters: {
    formatDecimal(value) {
      if(isNullOrEmpty(value))
        return 0;
      return parseFloat(value).toFixed(1);
    },
    formatDecimal2(value) {
      if(isNullOrEmpty(value))
        return 0;
      return parseFloat(value).toFixed(0);
    },
    formatDecimal3(value) {
      if(isNullOrEmpty(value))
        return '';
      return (parseFloat(value)/10000).toFixed(0)+'万';
    }
  },
  data() {
    return {
      query: this.$route.query,
      selectedValue: '3-1',
      payType:3,
      channel: 1,
      paymentList: [
        {
          name: '银联',
          icon: require('../../assets/img/payment/union.png'),
          type: 3,
          channel: 1,
          is_show: 'is_union',
        },
        {
          name: '支付宝A',
          icon: require('../../assets/img/payment/alipay.png'),
          type: 2,
          channel: 1,
          is_show: 'is_alipay',
        },
        {
          name: '支付宝B',
          icon: require('../../assets/img/payment/alipay.png'),
          type: 2,
          channel: 2,
          is_show: 'is_alipay',
        },
        {
          name: '微信支付A',
          icon: require('../../assets/img/payment/wxpay.png'),
          type: 1,
          channel: 1,
          is_show: 'is_weixin',
        },
        // {
        //   name: '微信支付B',
        //   icon: require('../../assets/img/payment/wxpay.png'),
        //   type: 1,
        //   channel: 2,
        //   is_show: 'is_weixin',
        // },
        {
          name: '聚合支付',
          icon: require('../../assets/img/payment/qq.png'),
          type: 4,
          channel: 1,
          is_show: 'is_qqpay',
        },
        {
          name: '银行卡',
          icon: require('../../assets/img/payment/bank_card.png'),
          type: 5,
          channel: 1,
          is_show: 'is_bank',
        },
        {
          name: '余额支付',
          icon: require('../../assets/img/payment/yue.png'),
          type: 7,
          channel: 1,
          is_show: 'is_balance',
        },
      ],
    };
  },
  mounted() {
    this.getSystemInfo();
  },
  methods: {
    ...mapActions("user", ["getUserInfo"]),
    ...mapActions("systemInfo", ["getSystemInfo"]),
    onClickLeft() {
      // 点击左侧返回按钮返回上一页
      this.$router.go(-1);
    },
    selectPayment(item) {
      this.payType = item.type;
      this.channel = item.channel;
      this.selectedValue = this.getCompositeKey(item.type, item.channel);
    },
    getCompositeKey(type, channel) {
      return `${type}-${channel}`;
    },
    onPay() {
      if (this.payType == 7) {
        this.rechargePay();
      }
      else if (this.payType == 5) {
        axios
            .post(api.addOrder, {
              source: 1,
              bid: this.query.id,
              amount: this.query.price,
            })
            .then((res) => {
              this.$router.push({
                name: "bankPay",
                query: {
                  order_sn: res.data.order_sn,
                  amount: this.query.price,
                  subtitle3: '价格',
                },
              });
            })
            .catch((err) => {
              this.$toast.clear();
              err.msg && this.$toast(err.msg);
            });

      } else {
        let params = {
          source: 1,
          paytype: parseInt(this.payType),
          channel: parseInt(this.channel),
          bid: this.query.id,
          amount: this.query.price,
        };
        this.$toast.loading({
          message: "支付中...",
          forbidClick: true,
        });
        axios
            .post(api.balancePay, params)
            .then((res) => {
              if (res.data?.url) {
                window.location.href = res.data.url;
              }
            })
            .catch((err) => {
              this.$toast.clear();
              err.msg && this.$toast(err.msg);
            });
      }
    },
    rechargePay() {
      let params = {
        source: 1,
        bid: this.query.id,
        amount: this.query.price,
      };
      this.$toast.loading({
        message: "支付中...",
        forbidClick: true,
      });
      axios
          .post(api.rechargePay, params)
          .then((res) => {
            this.$toast.clear();
            this.$toast('购买成功！')
            this.getUserInfo();
            this.onClickLeft();
          })
          .catch((err) => {
            this.$toast.clear();
            err.msg && this.$toast(err.msg);
          });
    }
  },
}
</script>

<style scoped lang="less">

.welfare-list {
  padding: 15px 10px;
}

.welfare-list .item {
  background-color: #d3281d;
  border-radius: 12px;
  margin-bottom: 15px;
  padding: 15px 0px;
}

.welfare-list .item .title {
  padding-bottom: 15px;
  color: #1a1d27;
  font-size: 16px;
  font-weight: 550;
  text-align: center;
}

.welfare-list .item .ul {
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: center;
}

.welfare-list .item .ul li {
  width: 33%;
}

.welfare-list .item .ul li+li {
}

.welfare-list .item .pre {
  color: #FDE0B4;
  font-size: 16px;
  font-weight: 550;
  margin-bottom: 6px;
}



.welfare-list .item p {
  font-size: 14px;
  color: #7a726a;
}

.annius_S {
  text-align: center;
}

.annius_S button {
  background: linear-gradient(to right, #e32429, #e32429);
  border: none;
  margin: 20px auto auto;
  color: #fff;
  border-radius: 40px;
  width: 90%;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
}

</style>
