<template>
  <div class="h-full w-full bg-AC1E21">

    <div class="overflow-hidden w-full">
      <div class="page-content-2">
        <PullRefresh v-model="refreshing" @refresh="refreshData" class="h-full">
          <list
              v-model="loading"
              :finished="finished"
              @load="loadMore"
              @refresh="refreshData"
          >
            <div class="p-2 flex flex-col justify-center items-center bg-page" >

              <div class="bg-white w-full p-2 flex flex-col mb-6 rounded-lg relative items-center justify-center"
                   v-for="(item,index) in list"
                   :key="index">
                <div class="flex flex-row items-center justify-between w-full mb-2">
                  <div class="text-base font-bold ">
                    韩红<span class="text-red-500">{{ item.title }}</span>
                  </div>
                  <div class="text-red-500 font-bold text-base">
                     {{ item.price }}元
                  </div>
                </div>

                <div class="flex flex-row items-center justify-center w-full mb-4">
                  <div class="bg-stock relative">
                    <img src="@/assets/img/dividend/bg-gift-content.png" style="height: 40px;"/>
                    <div class="absolute inset-0 flex flex-row items-center justify-center text-center">每月发放 <span style="color:#E60012">{{ item.total|formatDecimal2 }}</span> 生活补贴金</div>
                  </div>
                </div>

                <div
                     class="justify-self-center absolute -bottom-3 buy-btn"
                     @click="jumpToPay(item)">
                   {{item.price==799?'已售罄':'立即购买'}}
                </div>
              </div>
            </div>
          </list>
          <Empty v-if="isNullOrEmpty(list)&&loading===false&&refreshing==false" description="暂无数据"/>
        </PullRefresh>
      </div>
    </div>
  </div>
</template>


<script>
import axios from "@/net/axios";
import api from "@/net/api";
import {Empty, List,   PullRefresh,} from "vant";
import {isNullOrEmpty} from "@/utils";

export default {
  name: "index",
  components: { List, Empty, PullRefresh},
  data() {
    return {
      isNullOrEmpty,
      list:[],
      loading: false,
      finished: false,
      refreshing: false,
    };
  },
  filters: {
    formatDecimal(value) {
      if(isNullOrEmpty(value))
        return 0;
      return parseFloat(value).toFixed(1);
    },
    formatDecimal2(value) {
      if(isNullOrEmpty(value))
        return 0;
      return parseFloat(value).toFixed(0);
    },
    formatDecimal3(value) {
      if(isNullOrEmpty(value))
        return '';
      return (parseFloat(value)/10000).toFixed(0)+'万';
    }
  },
  computed: {
    title() {
      return this.$route.meta.title; // 获取路由的meta属性的title值
    }
  },
  methods: {
    refreshData() {
      this.refreshing = true
      this.loadMore()
    },
    loadMore() {
      axios
          .get(api.welfareList)
          .then((res) => {
            this.list = res.data.filter(item => item.ntype === 2);
            this.loading = false
            this.refreshing = false
            this.finished = true;
          })
          .catch((err) => {
            this.loading = false
            this.finished = true
            this.refreshing = false
            err.msg && this.$toast(err.msg);
          });
    },
    jumpToPay(data) {
      if(data.price==799){
        this.$dialog.alert({
          title: '提示',
          message: '该礼包已售罄',
        });
        return;
      }
      this.$router.push({
        name: "buyDividend",
        query: {
          id: data.id,
          annuity: data.annuity,
          bonus: data.total,
          stock: data.stock,
          price: data.price,
          title: '韩红'+data.title,
          subtitle: '每月生活补贴金',
          subtitle3: '价格',
        },
      });
    },

  },
};
</script>

<style scoped lang="less">
//.van-nav-bar {
//  background-color: #B41212;
//}
//
//.van-nav-bar /deep/ .van-nav-bar__title {
//  color: #fff;
//}
.bg-page {
  background-image: url('../../assets/img/dividend/bg-gift.png');
  background-size: 100% auto;
  background-repeat: no-repeat;
  padding-top: 220px;
}
.bg-stock {
  background-image: url('../../assets/img/dividend/bg-gift-content.png');
  background-size: 100% auto;
  background-repeat: no-repeat;
  color: #B45605;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.welfare-list-item {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #333;
  font-size: 14px;
  margin-bottom: 30px;
  flex-direction: column;
}

.list-item-content {
  top: 30px;
  background: #FFE7C1;
  border-radius: 20px;
  padding: 40px 20px 20px;
  display: flex;
  flex-direction: column;

}

.list-item-content .btn {
  background-image: linear-gradient(180deg, #FDE0B4 0%, #FFC771 100%);
  border-radius: 16px;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #AB0F13;
  align-self: center;
  margin-top: 20px;
}

.content-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: 0.5;
  background: #FFFFFF;
  color: #3E3E3E;
  padding: 5px 15px;
  font-size: 12px;
  margin-top: 20px;
}

.progress-box {
  width: 60%;
  background-color: #AB0F13;
  height: 10px;
  margin: 0 10px;
  opacity: 1;
}

.buy-btn {
  background: linear-gradient(90deg, #DA1A0C, #FFB533);
  border-radius: 55px;
  color: #FFFFFF;
  font-size: 14px;
  padding: 5px 20px;
  cursor: pointer;
}

.page-content /deep/ .van-loading__text {
  color: #fff;
}

.page-content /deep/ .van-pull-refresh__head {
  color: #fff;
}

.page-content /deep/ .van-empty__description {
  color: #fff;
}
</style>
