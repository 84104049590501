<template>
  <div class="px-4 flex flex-col w-full">
    <div @click="jumpToPage(item.id)" class="bg-white rounded-lg w-full flex flex-col p-2 mb-2"
         v-for="(item, index) in newsList" :key="index">
      <p class="title">{{ item.title }}</p>
      <div class="flex flex-row ">
        <img style="height: 80px;margin-right: 10px" :src="item.thumb" alt=""/>
        <p class="lin-text">
          {{ item.description }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>

import axios from "@/net/axios";
import api from "@/net/api";

export default {
  data() {
    return {
      newsList: [],
    };
  },
  mounted: function () {
    this.getNewsList();
  },
  methods: {
    jumpTo(name) {
      this.$router.push({name})
    },
    jumpToPage(id) {
      this.$router.push({
        name: 'newsDetails',
        params: {
          id
        }
      })
    },
    getNewsList: function () {
      axios
          .get(api.newsList)
          .then((res) => {
            this.newsList = res.data;
          })
          .catch((err) => {
            err.msg && this.$toast(err.msg);
          });
    },
  },
};
</script>
<style scoped lang="less">


.title {
  font-size: 15px;
  font-weight: 550;
  margin-top: 0px;
  color: #333333;
  line-height: 22px;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 10px;
}

.lin-text {
  font-size: 14px;
  margin-top: 0px;
  color: #666666;
  line-height: 20px;
  -webkit-line-clamp: 4;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}


</style>
