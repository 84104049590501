

export default {
  login: "/apiV1/login/login", //登录
  register: "/apiV1/login/register", //注册
  navList: "/apiV1/navigation/list", //菜单
  getBanner: "/apiV1/index/ad", //获取banner
  signIn: "/apiV1/user/sign", //用户签到
  systemInfo: "/apiV1/site/system", //获取系统信息
  userInfo: "/apiV1/user/user-info", //获取用户信息
  realAuth: "/apiV1/user/real-auth", //实名认证
  bindBankCard: "/apiV1/user/bank-edit", //绑定银行卡
  teamList: "/apiV1/user/teamlist", //团队列表
  uploadAvatar: "/apiV1/user/avatar", //上传头像
  balanceLog: "/apiV1/user/balance-log", //资金明细
  newsList: "/apiV1/article/list", //新闻列表
  newsDetails: "/apiV1/article/detail", //新闻详情
  userWallet: "/apiV1/user/user-wallet", //用户钱包
  drawNum: "/apiV1/user/draw-num", //剩余抽奖次数
  luckNum: "/apiV1/user/luck-draw", //抽奖
  drawRecord: "/apiV1/user/draw-record", //抽奖记录
  userWithdrawal: "/apiV1/user/with-save", //用户提现
  withdrawalRecordList: "/apiV1/user/withlog", //提现记录
  myHouse: "/apiV1/user/my-house", //我的房产
  receiveList: "/apiV1/user/receive-list", //已经领取的奖励列表
  receiveAward: "/apiV1/user/receive", //领取奖励
  receiveNote: "/apiV1/user/receive-note", //领取本票
  cardAddressList: "/apiV1/user/card-address-list ", //卡包
  welfareList: "/apiV1/welfare/index", //分红股
  balancePay: "/apiV1/pay/pay", //支付
  rechargePay: "/apiV1/pay/recharge", //支付
  buyBonusList: "/apiV1/user/buylog", //购买分红股列表
  reviceReg: "/apiV1/user/receive-reg", //注册领取奖励
  addressList: "/apiV1/address/list ", //地址列表
  addressDetail: "/apiV1/address/detail", //地址详情
  addAddress: "/apiV1/address/add", //添加地址
  editAddress: "/apiV1/address/edit", //编辑地址
  deleteAddress: "/apiV1/address/del", //删除地址
  modifyPwd: "/apiV1/user/savepwd", //修改密码
  siteCode: "/site/code", //验证码
  addOrder: "/apiV1/pay/add-order", //生成订单
  uploadPaymentImg: "/apiV1/pay/payment", //上传支付凭证

};
