<template>
  <div class="page-container h-screen">
    <nav-bar
        :title="data.title"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        :safe-area-inset-top="true"
        class="w-full"
        :border="false"
    />
    <div class="overflow-hidden w-full">
      <div class="overflow-y-auto h-full">
        <div class="rounded bg-white p-2 m-4">
          <div class="flex flex-row items-center mb-2 justify-between text-base">
            <div>提现金额(元)</div>
            <div class="text-red-500">满{{minWidthMoney}}可提现</div>
          </div>

          <div class="flex flex-row items-center flex-wrap">
            <div
                class="w-1/4 flex flex-row items-center mb-2 "
                v-for="(item,index) in amountList" :key="index" @click="onChangeIndex(index)">
              <div class="flex rounded bg-white w-11/12  px-2 py-2 "
                   :class="{'bg-primary text-white':selectIndex==index,'border text-black':selectIndex!=index}">
                {{ item.name }}
              </div>
            </div>
          </div>
          <field
              v-model="amount"
              type="number"
              name="提现数额"
              label="¥"
              placeholder="请输入提现数额"
              :center="true"
              input-align="left"
          />

          <div class="text-base mt-2 ml-4">
            可提现金额{{ balance }}
          </div>
          <div class="mt-8 mb-2 mx-2">
            <Button round block type="info" color="#FF5848" native-type="submit" @click="onSubmit">立即提现</Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>


import {mapActions, mapState} from "vuex";
import {NavBar, Button, Field} from "vant";
import axios from "@/net/axios";
import api from "@/net/api";
import {WithdrawalType} from "@/config";
import {isNullOrEmpty} from "@/utils";

export default {
  components: {NavBar, Button, Field},
  computed: {
    ...mapState("user", ["userInfo"]),
    minWidthMoney(){
      if(this.type=='annuity'||this.type=='bonus'||this.type=='balance'){
        return 1000 // 获取路由的meta属性的title值
      }
      else if(this.type=='salary'){
        return 100000
      }
      else if(this.type=='stock'){
        return 100000
      }
      else{
        return 100
      }
    },
    amountList(){
      if(this.type=='annuity'||this.type=='bonus'||this.type=='balance'){
        return  [
          {
            name: "¥1000",
            value: "1000",
          },
          {
            name: "¥2000",
            value: "2000",
          },
          {
            name: "¥3000",
            value: "3000",
          },
          {
            name: "¥5000",
            value: "5000",
          },
          {
            name: "¥10000",
            value: "10000",
          },
          {
            name: "¥20000",
            value: "20000",
          },
          {
            name: "¥30000",
            value: "30000",
          },
          {
            name: "¥50000",
            value: "50000",
          },
        ]
      }
      else if(this.type=='stock'||this.type=='salary'){
        return [
          {
            name: "10万",
            value: "100000",
          },
          {
            name: "20万",
            value: "200000",
          },
          {
            name: "30万",
            value: "300000",
          },
          {
            name: "50万",
            value: "500000",
          },
          {
            name: "100万",
            value: "1000000",
          },
          {
            name: "200万",
            value: "2000000",
          },
          {
            name: "300万",
            value: "3000000",
          },
          {
            name: "500万",
            value: "5000000",
          },
        ]
      }
      else{
        return  [
          {
            name: "¥300",
            value: "300",
          },
          {
            name: "¥500",
            value: "500",
          },
          {
            name: "¥1000",
            value: "1000",
          },
          {
            name: "¥2000",
            value: "2000",
          },
          {
            name: "¥3000",
            value: "3000",
          },
          {
            name: "¥5000",
            value: "5000",
          },
          {
            name: "¥10000",
            value: "10000",
          },
          {
            name: "¥20000",
            value: "20000",
          },
        ]
      }

    }
  },
  data() {
    return {
      data: this.$route.query,
      type: this.$route.query.type,
      WithdrawalType,
      amount: "",
      balance: this.$route.query.amount || 0,
      selectIndex: null,
    }
  },
  methods: {
    ...mapActions("user", ["getUserInfo"]),
    onClickLeft() {
      this.$router.go(-1);
    },
    onChangeIndex(index) {
      this.selectIndex = index;
      this.amount = this.amountList[index].value;
    },
    onSubmit(event) {
      event.preventDefault();
      const {amount} = this;
      if (isNullOrEmpty(amount)) {
        this.$toast("请输入提现数额");
        return
      }
      // console.log('申请提现',amount,this.balance)
      // if (parseFloat(amount) > parseFloat(this.balance)) {
      //   this.$toast("提现金额不能大于可用余额");
      //   return;
      // }
      // if (parseFloat(amount) <this.minWidthMoney) {
      //   this.$toast(`最低提现金额为${this.minWidthMoney}元`);
      //   return;
      // }
      // if (this.data.type === WithdrawalType.CASH) {
      //   if(this.userInfo.bonusStock==0)
      //   {
      //     this.$dialog.confirm({
      //       title: '提示',
      //       message: '购买分红股即可提现,是否立即去购买?',
      //     })
      //         .then(() => {
      //           this.$router.push({path: '/dividend'});
      //         })
      //         .catch(() => {
      //         });
      //     return
      //   }
      // }
      // if(this.data.type=='stock'){
        // this.$router.push({
        //   name: "paysxf",
        //   query: {
        //     bonus: this.amount,
        //     price: this.amount*0.01,
        //     title: '个人所得税',
        //     subtitle: '提现',
        //     subtitle3: '应缴个税',
        //   },
        // });
      // }
      // else{
        axios.post(api.userWithdrawal, {
          amount,
          type: this.data.type,
        }).then((res) => {
          this.$toast("申请成功，正在审核");
          this.getUserInfo();
          this.onClickLeft();
        }).catch((err) => {
          err.msg && this.$toast(err.msg);
        });
      // }

    },
  },
}
</script>

<style scoped lang="less">
.page-container /deep/ .van-field__label {
  width: auto !important;
  font-size: 24px;
  font-weight: bold;
}
</style>
