<template>
  <div class="bg-white flex flex-col  rounded-lg p-2">
    <div class="bg-F8574F rounded p-2 text-white text-sm font-bold flex flex-row items-center">
      <img src="@/assets/img/profile/ysgqb.png" style="height: 50px;margin-right: 5px;">
      <div class="flex-1">
        <div>原始股钱包（每股价值100元）</div>
        <div>{{ (walletInfo.stock * 100) || '0' }}</div>
      </div>
      <img src="@/assets/img/profile/ljtx.png" style="height: 30px;margin-right: 5px;"
           @click="jumpToWithdraw('stock','原始股提现',walletInfo.stock*100)">
    </div>
    <div class="withdrawal-container">
      <div class="w-1/2 py-2">
        <div class="flex flex-row items-center">
          <img src="@/assets/img/profile/fpjqb.png" alt="" style="height: 50px;">
          <div class="ml-1">
            <div class="text-black text-base">
              扶贫金钱包(元)
            </div>
            <div>
              <span>{{ walletInfo.salary || '0.00' }}</span>
            </div>
            <img src="@/assets/img/profile/ic_withdral.png" alt="" style="height: 25px;"
                 @click="jumpToWithdraw('salary','扶贫金',walletInfo.salary)"/>
          </div>
        </div>
      </div>
      <div class="w-2"></div>
      <div class="w-1/2 py-2">
        <div class="flex flex-row items-center">
          <img src="@/assets/img/profile/btjqb.png" alt="" style="height: 50px;">
          <div class="ml-1">
            <div class="text-black text-base">
              补贴金钱包(元)
            </div>
            <div>
              <span>{{ walletInfo.annuity || '0' }}</span>
            </div>
            <img src="@/assets/img/profile/ic_withdral.png" alt="" style="height: 25px;"
                 @click="jumpToWithdraw('annuity','补贴金',walletInfo.annuity)"/>
          </div>
        </div>
      </div>
    </div>
    <div class="withdrawal-container2">
      <div class="w-1/2 py-2">
        <div class="flex flex-row items-center">
          <img src="@/assets/img/profile/tgjqb.png" alt="" style="height: 50px;">
          <div class="ml-1">
            <div class="text-black text-base">
              推广金钱包(元)
            </div>
            <div>
              <span>{{ walletInfo.balance || '0' }}</span>
            </div>
            <img src="@/assets/img/profile/ic_withdral.png" alt="" style="height: 25px;"
                 @click="jumpToWithdraw('balance','推广金',walletInfo.balance)"/>
          </div>
        </div>
      </div>
      <div class="w-2"></div>
      <div class="w-1/2 py-2">
        <div class="flex flex-row items-center">
          <img src="@/assets/img/profile/qdjqb.png" alt="" style="height: 50px;">
          <div class="ml-1">
            <div class="text-black text-base">
              签到金钱包(元)
            </div>
            <div>
              <span>{{ walletInfo.invest || '0.00' }}</span>
            </div>
            <img src="@/assets/img/profile/ic_withdral.png" alt="" style="height: 25px;"
                 @click="jumpToWithdraw('invest','签到金',walletInfo.invest)"/>
          </div>
        </div>
      </div>
    </div>
    <div class="withdrawal-container2">
      <div class="w-1/2 py-2">
        <div class="flex flex-row items-center">
          <img src="@/assets/img/profile/ic_tjjqb.png" alt="" style="height: 50px;">
          <div class="ml-1">
            <div class="text-black text-base">
              推荐奖钱包(元)
            </div>
            <div>
              <span>{{ walletInfo.bonus || '0' }}</span>
            </div>
            <img src="@/assets/img/profile/ic_withdral.png" alt="" style="height: 25px;"
                 @click="jumpToWithdraw('bonus','推荐奖',walletInfo.bonus)"/>
          </div>
        </div>
      </div>
      <div class="w-2"></div>

    </div>
  </div>
</template>

<script>

import axios from "@/net/axios";
import api from "@/net/api";
import {mapState} from "vuex";

export default {
  props: ['pageKey'],
  data() {
    return {
      walletInfo: {},
    };
  },
  computed: {
    ...mapState("user", ["userInfo"]),
  },
  mounted() {
    this.getUserWallet();
  },

  methods: {
    getUserWallet() {
      axios.get(api.userWallet)
          .then((res) => {
            this.walletInfo = res.data;
          })
          .catch((err) => {
            console.log(err);
          });
    },
    jumpToWithdraw(type, title, amount) {
      // if (type == 'salary' && (this.userInfo.activate & 1) == 0&& (this.userInfo.activate & 2) == 0) {
      //   this.$dialog.alert({
      //     title: '提示',
      //     message: '参与扶贫攻坚任意项目即可提现',
      //     theme: 'round-button',
      //   }).then(() => {
      //     this.$bus.$emit('active', 1);
      //     this.$router.push({
      //       name: "dividend"
      //     });
      //   });
      //   return
      //
      // }
      if (type == 'stock'||type=='salary') {
        if((this.userInfo.activate & 8) == 0){
          this.$dialog.alert({
            title: '提示',
            message: '银行卡风控，需开通大额支付通道',
            theme: 'round-button',
          }).then(() => {
            this.$router.push({
              name: "payLarge",
              query: {
                price: 1000,
                title: '开通大额支付通道',
                subtitle3: '开通费',
              },
            });
          });
          return
        }
      }

        if(type=='salary'&&this.walletInfo.salary<100000.00){
          this.$dialog.alert({
                title: '提示',
                message: '扶贫金余额100000元起提',
                theme: 'round-button',
              }).then(() => {

              });
              return
        }
        if(type=='annuity'&&this.walletInfo.annuity<1000.00){
          this.$dialog.alert({
                title: '提示',
                message: '补贴金余额1000元起提',
                theme: 'round-button',
              }).then(() => {

              });
              return
        }
        if(type=='balance'&&this.walletInfo.balance<1000.00){
          this.$dialog.alert({
                title: '提示',
                message: '推广金余额1000元起提',
                theme: 'round-button',
              }).then(() => {

              });
              return
        }
        if(type=='bonus'&&this.walletInfo.bonus<1000.00){
          this.$dialog.alert({
                title: '提示',
                message: '推荐奖余额1000元起提',
                theme: 'round-button',
              }).then(() => {

              });
              return
        }
      // if (type == 'balance' && amount < 20) {
      //   this.$dialog.alert({
      //     title: '提示',
      //     message: '50块起提',
      //     theme: 'round-button',
      //   }).then(() => {
      //
      //   });
      //   return
      //
      // }
      this.$router.push({
        name: "withdrawal",
        query: {type, title, amount},
      });
    },
  },
};
</script>

<style lang="less" scoped>
.withdrawal-container {

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  color: #898989;
  font-size: 14px;

  /deep/ span {
    color: #FF0000;
  }
}

.withdrawal-container2 {

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  color: #898989;
  font-size: 14px;

  /deep/ span {
    color: #FF0000;
  }
}

.btn-primary {
  font-family: MiSans;
  font-size: 13px;
  font-weight: 400;
  line-height: 13px;
  text-align: left;
  color: #FFFFFF;
  background-color: #FDAA01;
  border-radius: 5px;
  padding: 5px 10px;
}

.item {
  width: 100%;
  border: 1px solid #FFD6D6;
  border-radius: 12.5px;
  padding: 10px 0;
}
</style>
