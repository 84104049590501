<template>
  <div class="flex flex-row justify-evenly py-3 mb-3">
    <div
        class="flex flex-col items-center justify-center"
        @click="jumpToPage('downloadApp')"
    >
      <img src="@/assets/img/index/ic_download.png" class="w-10">
      <div class="text-l text-b333 mt-1">App下载</div>
    </div>
    <div
        class="flex flex-col items-center justify-center"
        @click="jumpToPage('contactUs')"
    >
      <img src="@/assets/img/index/ic_group_chat.png" class="w-10">
      <div class="text-lg text-b333 mt-1">加入群聊</div>
    </div>
    <div
        class="flex flex-col items-center justify-center"
        @click="jumpToPage('inviteUser')"
    >
      <img src="@/assets/img/index/ic_invite.png" class="w-10">
      <div class="text-lg text-b333 mt-1">邀请好友</div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState("systemInfo", ["systemInfo"]),
  },
  methods: {
    ...mapActions("systemInfo", ["getSystemInfo"]),
    jumpToPage(name) {
      if (name == 'downloadApp') {
        window.location.href = this.$config.APP_URL.replace('./', '/');
        return
      }
      if (name == 'customService') {
        window.location.href = this.$config.KF_URL;
        return
      }
      this.$router.push({name});
    },
  },
};
</script>
<style scoped lang="less">
</style>
