<template>
  <div class="bg-E0C28E rounded-lg p-1 my-2">
    <div class="bg-white flex flex-col  rounded-lg p-2">
      <div class="text-black font-bold text-lg mb-2 ml-2">常用功能</div>
      <div class="w-full text-black text-base flex flex-row flex-wrap">
        <div class="w-1/4 flex flex-col justify-center items-center mb-2" @click="jumpToPage('team')">
          <img src="@/assets/img/profile/team.png" alt="" style="height: 25px;" class="mb-1">
          <span>我的团队</span>
        </div>
        <div class="w-1/4 flex flex-col justify-center items-center mb-2" @click="jumpToPage('buyBonusList')">
          <img src="@/assets/img/profile/gmjl.png" alt="" style="height: 25px;" class="mb-1">
          <span>我的投资</span>
        </div>
        <div class="w-1/4 flex flex-col justify-center items-center mb-2" @click="jumpToPage('bindBankCard')">
          <img src="@/assets/img/profile/yhbd.png" alt="" style="height: 25px;" class="mb-1">
          <span>银行绑定</span>
        </div>
        <div class="w-1/4 flex flex-col justify-center items-center mb-2" @click="jumpToPage('realNameAuth')">
          <img src="@/assets/img/profile/smrz.png" alt="" style="height: 25px;" class="mb-1">
          <span>实名认证</span>
        </div>
        <div class="w-1/4 flex flex-col justify-center items-center mb-2" @click="jumpToPage('fundDetails')">
          <img src="@/assets/img/profile/zjmx.png" alt="" style="height: 25px;" class="mb-1">
          <span>资金明细</span>
        </div>
        <div class="w-1/4 flex flex-col justify-center items-center mb-2" @click="jumpToPage('withdrawRecord')">
          <img src="@/assets/img/profile/txjl.png" alt="" style="height: 25px;" class="mb-1">
          <span>提现记录</span>
        </div>
        <div class="w-1/4 flex flex-col justify-center items-center mb-2" @click="jumpToPage('downloadApp')">
          <img src="@/assets/img/profile/download.png" alt="" style="height: 25px;" class="mb-1">
          <span>App下载</span>
        </div>
        <div class="w-1/4 flex flex-col justify-center items-center mb-2" @click="jumpToPage('contactUs')">
          <img src="@/assets/img/profile/zxkf.png" alt="" style="height: 25px;" class="mb-1">
          <span>官方群聊</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {},
  props: ['pageKey'],
  data() {
    return {};
  },

  mounted() {

  },

  methods: {
    jumpToPage(name) {
      if (name == 'downloadApp') {
        window.location.href = this.$config.APP_URL.replace('./', '/');
        return
      }
      if (name == 'customService') {
        window.location.href = this.$config.KF_URL;
        return
      }
      this.$router.push({name});
    },

  },
};
</script>

<style lang="less" scoped>
.container {
  background: #FFFFFF;
  opacity: 0.9;
  box-shadow: 0px 0px 11.6px 0px #C1C1C140;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  color: #898989;
  font-size: 14px;
  padding: 10px 0;
  margin-bottom: 10px
}

.count-img {
  height: 26px;
  margin-bottom: 6px;
}
</style>
